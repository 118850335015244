body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: url('/src/wand.png') 10 10, auto; */
}

/* a:hover, button:hover, link:hover {
  cursor: url('/src/wand.png') 10 10, pointer;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ADLaM';
  src: url('/public/font/ADLaM.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'ADLaM', sans-serif;
}

/* Apply only to specific elements */
.ga-name {
  font-family: 'ADLaM', sans-serif;
  font-weight: bold;
  font-size: 20px; /* Adjust size */
  color: #7066F3; /* Dark color for better readability */
}
